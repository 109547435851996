import { getConfig, ShowToolTipComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import { memo, useEffect, useState } from 'react'
import { getEnv } from "@urecruits/api"
import axios from 'axios'
import { setPermissionRefetch } from '../../store/rolesReducer'
import { store } from '../../store'

const { API_RECRUITMENT } = getEnv()
const doneIcon = require('../../image/icon/small_done_ic.svg')
const addCompanyRole=async(roleName)=>{
  try{
    return await axios.post(`${API_RECRUITMENT}/api/company/role/create`, { roleName }, getConfig())
  }catch(error){
    console.log("Error adding role to company:",error)
  }
}
const SelectRolesItem = ({
  strapiRoleItem,
  currentUserData,
  setRolesPopupData,
  setNewRoleData,
  newRoleData,
  setRoleToggle,
}) => {
  const [status, setStatus] = useState(!!currentUserData.roles.find(x => x.value === strapiRoleItem.auth0_id))

  useEffect(() => {
    setStatus(!!currentUserData.roles.find(x => x.value === strapiRoleItem.auth0_id))
  }, [currentUserData]);
  const onRoleChange = () => {
    const tempStatus = !status
    if (tempStatus) {
      const newRole = [...currentUserData.roles, {
        value: strapiRoleItem.auth0_id,
        label: strapiRoleItem.role,
        id:strapiRoleItem.id
      }]
      setStatus((prev) => !prev)
      setNewRoleData(newRole)
      setRolesPopupData({
        userName: `${currentUserData.firstname} ${currentUserData.lastname}`,
        userAuthId: currentUserData.authId,
        visible: true,
        prevRoles: currentUserData.roles,
        newRoles: newRole
      })
      setRoleToggle(false)
    } else {
      if (currentUserData.roles.length > 1) {
        let tempVal = currentUserData.roles
        tempVal = tempVal.filter((element) => {
          return element.value !== strapiRoleItem.auth0_id
        })
        setNewRoleData(tempVal)
        setStatus((prev) => !prev)
        setRolesPopupData({
          userName: `${currentUserData.firstname} ${currentUserData.lastname}`,
          userAuthId: currentUserData.authId,
          visible: true,
          prevRoles: currentUserData.roles,
          newRoles: [...tempVal]
        })
        setRoleToggle(false)
      }
    }
  }

  return (
    <div
      className={`select-roles__item ${status ? 'active' : ''}`}
      onClick={() => {
        if(strapiRoleItem.isDefault){
          return;
        }else{
          onRoleChange()
        }
      }}
    >
      <div className="select-roles__checkbox">
        <label
          htmlFor="confirmCheckbox"
          className="select-roles__checkbox__label"
        >
          <img src={doneIcon} alt="" className="select-roles__checkbox__icon"/>
        </label>
      </div>
      <p className="select-roles__name">
        {strapiRoleItem.role} {strapiRoleItem.isDefault ? <ShowToolTipComponent text='Add this role to your tenant to assign it'>{'('}<span className='select-roles__add__link' onClick={()=>addCompanyRole(strapiRoleItem.role).then(data => store.dispatch(setPermissionRefetch(true)))}>Add</span>{')'}</ShowToolTipComponent> : ''}
      </p>
    </div>
  )
}

export default memo(SelectRolesItem)