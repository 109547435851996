import { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { getConfig, SmallLoaderComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const userAvatar = require('../../image/icon/avatar.svg')
const deleteIc = require('../../image/icon/delete_ic.svg')
const backIc = require('../../image/icon/arrow_back_ic.svg')
const uploadIc = require('../../image/icon/upload-cloud_ic.svg')

const PopupPictureUpload = ({ setDisplayUploadPopup, data, setRefetch }) => {
  const [avatar, setAvatar] = useState('')
  const [loader, setLoader] = useState(false)
  const [newImage, setNewImage] = useState('')
  const token: string = localStorage.getItem('token')
  const [step, setStep] = useState(1)

  const [fileTypeError, setFileTypeError] = useState(false)
  const [fileSizeError, setFileSizeError] = useState(false)
  const { API_RECRUITMENT } = getEnv()

  const handleNewFileUpload = async (e) => {
    //validate
    e.target.files[0].size / 1024 > 5000 ? setFileSizeError(true) : setFileSizeError(false)
    e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png'
      ? setFileTypeError(false)
      : setFileTypeError(true)

    if (fileValidate(e.target.files[0])) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('upload', file)
      setLoader(true)
      const { data } = await axios.post(`${API_RECRUITMENT}/api/file/public`, formData, getConfig())
      setNewImage(data)
      setLoader(false)
      setStep(3)
    }
  }

  useEffect(() => {
    if (data) {
      setAvatar(data.avatar ? data.avatar : userAvatar)
    }
  }, [data])

  const onDeleteHandler = async (e) => {
    await changeAvatar(e, true)
  }

  const changeAvatar = async (e, deleteStatus) => {
    e.preventDefault()
    const token: string = localStorage.getItem('token')
    const tmpUser = {
      avatar: deleteStatus ? null : newImage,
    }

    await axios.patch(`${API_RECRUITMENT}/api/company`, tmpUser, getConfig())
    setDisplayUploadPopup(false)
    setRefetch(new Date())
  }

  const fileValidate = (value) => {
    return value.size / 1024 < 5000 && (value.type === 'image/jpeg' || value.type === 'image/png')
  }

  return (
    <div className="popup">
      {step == 1 && (
        <div className="popup__step">
          <div className="popup__step__head">
            <p className="popup__step__headline">
              Profile picture
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="popup__step__close"
              onClick={() => setDisplayUploadPopup(false)}>
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="popup__step__body">
            <img src={!avatar ? userAvatar : avatar} alt="user avatar" className="popup__step__avatar" />
            <p className="popup__step__info">
              The image helps people get to know you, and it can build trust.
            </p>
          </div>
          <div className="popup__step__control">
            <div className="popup__step__remove" onClick={(e) => onDeleteHandler(e)}>
              <img src={deleteIc} alt="delete icon" className="popup__step__remove--icon" />
              <p className="popup__step__remove--text">
                Remove
              </p>
            </div>
            <div className="popup__step__button button--filled" onClick={() => setStep(2)}>
              Change
            </div>
          </div>
        </div>
      )}
      {step == 2 && (
        <div className="popup__step second">
          <div className="popup__step__head">
            <div className="popup__step__back" onClick={() => setStep(1)}>
              <img src={backIc} alt="back icon" className="popup__step__back--icon" />
              <p className="popup__step__back--text">Back</p>
            </div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="popup__step__close"
              onClick={() => setDisplayUploadPopup(false)}>
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="popup__step__label">
            upload new files
          </div>
          <form className="drag">
            <label htmlFor="fileUpload" className="drag__inner">
              {
                loader ?
                  <SmallLoaderComponent />
                  :
                  <>
                    <input
                      type="file"
                      onChange={handleNewFileUpload}
                      accept="image/*"
                      id="fileUpload"
                      className="drag__input"
                    />
                    <p className="drag__headline">
                      Drag your files here
                    </p>
                    <div className="drag__information">
                      <p className="drag__information__text">
                        or click
                      </p>
                      <div className="drag__information__upload">
                        <img src={uploadIc} alt="upload icon" className="drag__information__upload--icon" />
                        <p className="drag__information__upload--text">
                          Upload
                        </p>
                      </div>
                    </div>
                  </>
              }
            </label>
          </form>
          <div className="popup__step__control">
            <div className="popup__step__remove">
              <div className="popup__step__button button--empty" onClick={() => setDisplayUploadPopup(false)}>
                Cancel
              </div>
            </div>
            <p className="error-message">
              {fileSizeError ? 'File size must be less than 5 MB.' : ''} {fileTypeError ? ' Incorrect file type.' : ''}
            </p>
          </div>
        </div>
      )}
      {step == 3 && (
        <div className="popup__step">
          <div className="popup__step__head">
            <p className="popup__step__headline">
              Profile picture
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="popup__step__close"
              onClick={() => setDisplayUploadPopup(false)}>
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="popup__step__body">
            <img src={newImage} alt="user avatar" className="popup__step__avatar" />
            <p className="popup__step__info">
              The image helps people get to know you, and it can build trust.
            </p>
          </div>
          <div className="popup__step__control">
            <div className="popup__step__remove" onClick={(e) => onDeleteHandler(e)}>
              <img src={deleteIc} alt="delete icon" className="popup__step__remove--icon" />
              <p className="popup__step__remove--text">
                Remove
              </p>
            </div>
            <button className="popup__step__button button--filled" type="submit" onClick={e => changeAvatar(e, false)}>
              Change
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(PopupPictureUpload)