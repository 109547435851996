import { useState, useEffect, memo } from 'react'
import { selectDisableStyle } from '../../../styles/selectDisableStyle'
import { selectCustomStyle } from '../../../styles/selectCustomStyle'
import PhoneInput from 'react-phone-number-input'
import { validateEmail } from '../../../hook/validateEmail'
import { validatePhoneNumber } from '../../../hook/validatePhoneNumber'
import { getEnv, GlobalConstants } from "@urecruits/api"
import { SelectLocationComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const removeIc = require('../../../image/icon/delete_ic.svg')
const cameraIc = require('../../../image/icon/camera_ic.svg')

const CompanyAddressItem =
  ({
    dataDetails,
    currentItem,
    setDataDetails,
    editMode,
    displayButton,
    validateStatus,
    setValidateStatus,
    addedMoreEducationButtonRef,
    submitButtonRef,
    index,
    countryList,
    companyName,
    contactPersonEmail,
    contactPersonPhone,
    companyType,
    contactPersonFirstName,
    contactPersonMiddleName,
    contactPersonLastName,
    aboutTheCompany,
    companyIndustry,
    setRefetch,
    companyNameError,
    setCompanyName,
    setCompanyNameError,
    setEditStatusForPopup,
    setDisplayUploadPopup,
    avatar,
     setDisplayButton
  }) => {
    const {API_RECRUITMENT}=getEnv()

    const [companyStreetError, setCompanyStreetError] = useState(false)
    const [companyLocationError, setCompanyLocationError] = useState(false)
    const [companyCountryError, setCompanyCountryError] = useState(false)
    const [companyEmailError, setCompanyEmailError] = useState(false)
    const [companyPhoneError, setCompanyPhoneError] = useState(false)
    const {phoneValidationLength}=GlobalConstants()

    const fieldsValidate = () => {
      let error = 0
      dataDetails.map((object) => {

        !object.street || object.street.length <= 0 ? setCompanyStreetError(true) : setCompanyStreetError(false)
        !object.location || object.location.length <= 0 ? setCompanyLocationError(true) : setCompanyLocationError(false)
        !object.country || object.country.length <= 0 ? setCompanyCountryError(true) : setCompanyCountryError(false)
        !object.email || !validateEmail(object.email) ? setCompanyEmailError(true) : setCompanyEmailError(false)
        !object.phone || !validatePhoneNumber(object.phone) && object.phone.length <= phoneValidationLength ? setCompanyPhoneError(true) : setCompanyPhoneError(false)

        if (!object.street || object.street.length <= 0 || !object.location || object.location.length <= 0 || !object.country || object.country.length <= 0 ||
          !object.phone || !validatePhoneNumber(object.phone)) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }

    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreEducationButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, dataDetails, currentItem, editMode, companyName,
      contactPersonEmail, contactPersonPhone, companyType, contactPersonFirstName,
      contactPersonMiddleName, contactPersonLastName, aboutTheCompany, companyIndustry])


    const onRemoveItemHandler = async () => {
      if (editMode) {
        setDataDetails(dataDetails.filter(x => x.id !== currentItem.id))
        setDisplayButton(true)
      }
    }

    return (
      <>
        <div className={`profile__form__group component`}>
          {
            index !== 0 && (
              <div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
                <div className="profile__form__delete--inner" onClick={onRemoveItemHandler}>
                  <span className="profile__form__delete--text">Delete</span>
                  <img src={removeIc} alt="delete icon" className="profile__form__delete--icon"/>
                </div>
              </div>
            )
          }
          {
            index === 0 && (
              <>
                <div className="profile__form__item company-avatar">
                  <div
                    className={`profile__form__avatar ${!editMode ? 'default' : ''}`}
                    onClick={() => {
                      setEditStatusForPopup(editMode)
                      setDisplayUploadPopup(true)
                    }}
                  >
                    <div className="profile__form__animation">
                      <img src={cameraIc} alt="icon" className="profile__form__animation--icon"/>
                    </div>
                    <img src={avatar} alt="user avatar" className="profile__form__avatar--image"/>
                  </div>
                </div>
                <div className={`profile__form__item ${companyNameError ? 'error' : ''} `}>
                  <label className="profile__form__label">Company Name{editMode && <span> *</span>}</label>
                  <input
                    type="text"
                    className="profile__form__input"
                    placeholder={`${editMode ? 'Enter company name' : 'Not indicated'}`}
                    readOnly={!editMode}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value)
                      e.target.value.length === 0 ? setCompanyNameError(true) : setCompanyNameError(false)
                    }}
                  />
                  <p className="error-message">
                    This is required field
                  </p>
                </div>
              </>
            )
          }
          <div className={`profile__form__item ${companyStreetError ? 'error' : ''}`}>
            <label className="profile__form__label">Company Street{editMode && <span> *</span>}</label>
            <input
              type="text"
              className="profile__form__input"
              placeholder={`${editMode ? 'Enter company street' : 'Not indicated'}`}
              readOnly={!editMode}
              value={currentItem.street ? currentItem.street : ''}
              onChange={(e) => {
                setDataDetails([...dataDetails].map(object => {
                  if (object.id === currentItem.id) {
                    return {
                      ...object,
                      street: e.target.value,
                    }
                  } else return object
                }))
                e.target.value.length <= 0 ? setCompanyStreetError(true) : setCompanyStreetError(false)
              }}
            />
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item ${companyCountryError ? 'error' : ''}`}>
            <label className="profile__form__label">Company Country{editMode && <span> *</span>}</label>
            <SelectLocationComponent
                fields={[
                  {
                    type:'country',
                    selectedValue: currentItem.country ? {name:currentItem.country} : null,
                    setSelectedValue: (newValue) => {
                        setDataDetails([...dataDetails].map(object => {
                          if (object.id === currentItem.id) {
                            return {
                              ...object,
                              country: newValue?.name||null,
                            }
                          } else return object
                        }))
                        newValue ? setCompanyCountryError(false) : setCompanyCountryError(true)
                    },
                    placeHolder: `${editMode ? 'Enter company country' : 'Not indicated'}`,
                    isDisabled: !editMode,
                    customSelectStyle: !editMode ? selectDisableStyle : selectCustomStyle,
                  }
                ]}
            />
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item ${companyLocationError ? 'error' : ''}`}>
            <label className="profile__form__label">Company State{editMode && <span> *</span>}</label>
            <SelectLocationComponent
                fields={[
                  {
                    type:'state',
                    selectedValue: currentItem.location ? {name:currentItem.location} : null,
                    setSelectedValue: (newValue) => {
                        setDataDetails([...dataDetails].map(object => {
                          if (object.id === currentItem.id) {
                            return {
                              ...object,
                              location: newValue?.name||null,
                            }
                          } else return object
                        }))
                        newValue ? setCompanyLocationError(false) : setCompanyLocationError(true)
                    },
                    placeHolder: `${editMode ? 'Enter State' : 'Not indicated'}`,
                    isDisabled: !editMode,
                    customSelectStyle: !editMode ? selectDisableStyle : selectCustomStyle,
                  }
                ]}
            />
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item ${companyEmailError ? 'error' : ''}`}>
            <label className="profile__form__label">Company Email{editMode && <span> *</span>}</label>
            <input
              type="email"
              className="profile__form__input"
              placeholder={`${editMode ? 'Enter company email' : 'Not indicated'}`}
              value={currentItem.email ? currentItem.email : ''}
              readOnly={!editMode}
              onChange={(e) => {
                setDataDetails([...dataDetails].map(object => {
                  if (object.id === currentItem.id) {
                    return {
                      ...object,
                      email: e.target.value,
                    }
                  } else return object
                }))
                validateEmail(e.target.value) ? setCompanyEmailError(false) : setCompanyEmailError(true)
              }}
            />
            <p className="error-message">
              Please enter a valid Email address correct
            </p>
          </div>
          <div className={`profile__form__item ${companyPhoneError ? 'error' : ''}`}>
            <label className="profile__form__label">Company Phone{editMode && <span> *</span>}</label>
            <PhoneInput
              international
              defaultCountry="US"
              value={currentItem.phone}
              disabled={!editMode}
              className={!editMode ? 'default' : ''}
              onChange={(value: any) => {
                setDataDetails([...dataDetails].map(object => {
                  if (object.id === currentItem.id) {
                    return {
                      ...object,
                      phone: value,
                    }
                  } else return object
                }))
                validatePhoneNumber(value) ? setCompanyPhoneError(false) : setCompanyPhoneError(true)
              }}
              placeholder={`${editMode ? '' : 'Not indicated'}`}
            />
            <p className="error-message">
              Phone number not correct
            </p>
          </div>
        </div>
      </>
    )
  }

export default memo(CompanyAddressItem)
