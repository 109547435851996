import DatePicker from 'react-datepicker'
import { memo, useEffect, useState } from 'react'
import { default as ReactSelect } from 'react-select'
import { selectDisableStyle } from '../../../styles/selectDisableStyle'
import { selectCustomStyle } from '../../../styles/selectCustomStyle'
import axios from 'axios'
import { getEnv, selectSearchFuncLocationLimit } from "@urecruits/api"
import { FilterWithLimitComponent, getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const deleteIc = require('../../../image/icon/delete_ic.svg')

const ExperienceDetailsTabItem =
  ({
    experienceDetails,
    item,
    setExperienceDetails,
    editMode,
    validateStatus,
    setValidateStatus,
    displayButton,
    addedMoreButtonRef,
    submitButtonRef,
    index,
    positionOption,
    countryList,
    recruiterId,
    setRefetch,
    setDisplayButton,
  }) => {
    const token: string = localStorage.getItem('token')
    const [companyNameError, setCompanyNameError] = useState(false)
    const [positionError, setPositionError] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [servicePeriodsStartDateError, setServicePeriodsStartDateError] = useState(false)
    const [servicePeriodsEndDateError, setServicePeriodsEndDateError] = useState(false)

    const fieldsValidate = () => {
      let error = 0

      experienceDetails.map((object) => {
        !object.companyName || object.companyName.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false)
        !object.position || object.position.length <= 0 ? setPositionError(true) : setPositionError(false)
        !object.location || object.location.length <= 0 ? setLocationError(true) : setLocationError(false)
        !object.start ? setServicePeriodsStartDateError(true) : setServicePeriodsStartDateError(false)
        !object.end ? setServicePeriodsEndDateError(true) : setServicePeriodsEndDateError(false)

        if (!object.location || !object.position || !object.companyName || object.companyName.length <= 0 || object.position.length <= 0 ||
          object.location.length <= 0
          || !object.start || !object.end) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }

    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, experienceDetails, item, editMode])
    const { API_RECRUITMENT } = getEnv()
    const onRemoveItemHandler = async () => {
      if (editMode) {
        const data = {
          id: item.id,
          recruiterId: recruiterId,
        }
        await axios.delete(`${API_RECRUITMENT}/api/recruiter/experience`, { ...getConfig(), data }).then((res) => {
          setRefetch(new Date())
        })
      }
    }

    return (
      <div className="profile__form__part">
        {
          index !== 0 && (
            <div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
              <div className="profile__form__delete--inner" onClick={onRemoveItemHandler}>
                <span className="profile__form__delete--text">Delete</span>
                <img src={deleteIc} alt="delete icon" className="profile__form__delete--icon" />
              </div>
            </div>
          )
        }
        <div className="profile__form__group">
          <div className={`profile__form__item ${companyNameError ? 'error' : ''}`}>
            <label className="profile__form__label">Company Name<span> *</span></label>
            <input
              type="text"
              className="profile__form__input"
              placeholder={`${editMode ? 'Enter your company name' : 'Not indicated'}`}
              readOnly={!editMode}
              value={item.companyName ? item.companyName : ''}
              onChange={(e) => {
                setExperienceDetails([...experienceDetails].map(object => {
                  if (object.id === item.id) {
                    return {
                      ...object,
                      companyName: e.target.value,
                    }
                  } else return object
                }))
                e.target.value.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false)
              }}
            />
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item ${positionError ? 'error' : ''}`}>
            <label className="profile__form__label">Position<span> *</span></label>
            <ReactSelect
              options={positionOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setDisplayButton(true)
                setExperienceDetails([...experienceDetails].map(object => {
                  if (object.id === item.id) {
                    return {
                      ...object,
                      position: currentValue.label,
                    }
                  } else return object
                }))
                currentValue.length <= 0 ? setPositionError(true) : setPositionError(false)
              }}
              value={item.position ? positionOption.find(x => x.label === item.position) : null}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select your position' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="positionSelect"
              instanceId="positionSelect"
            />
            <p className="error-message">This is required field</p>
          </div>
        </div>
        <div className="profile__form__group">
          <div className={`profile__form__item short ${locationError ? 'error' : ''}`}>
            <label className="profile__form__label">Location<span> *</span></label>
            <FilterWithLimitComponent
              isMulti={false}
              filter={{
                onChangeValue: (option) => {
                  setDisplayButton(true);
                    setExperienceDetails([...experienceDetails].map(object => {
                      if (object.id === item.id) {
                        return {
                          ...object,
                          location: option?.label||null,
                        };
                      } else return object;
                    }));
                    option?.label? setLocationError(false) : setLocationError(true)
                },
                value: item.location ? [{ label: item.location }] : null
              }}
              placeHolder={`${editMode ? "Select location" : "Not indicated"}`}
              setLimitSearchfunction={selectSearchFuncLocationLimit}
              customeSelectStyle={!editMode ? selectDisableStyle : selectCustomStyle}
            />
            {editMode && <p className="error-message">This is required field</p>}
          </div>
          <div className={`profile__form__item short after-line ${servicePeriodsStartDateError ? 'error' : ''}`}>
            <label className="profile__form__label">Service Periods<span> *</span></label>
            <div className="profile__form__date">
              <DatePicker
                showYearDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={50}
                selected={item.start ? new Date(item.start) : null}
                onChange={(date: any) => {
                  setDisplayButton(true)
                  setExperienceDetails([...experienceDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        start: date,
                      }
                    } else return object
                  }))
                  !date ? setServicePeriodsStartDateError(true) : setServicePeriodsStartDateError(false)
                }}
                dateFormat="MMMM d, yyyy"
                maxDate={new Date()}
                readOnly={!editMode}
                placeholderText="Enter service periods start"
              />
            </div>
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item short ${servicePeriodsEndDateError ? 'error' : ''}`}>
            <div className={`profile__form__date ${!item.start && 'profile__form__date--readonly'}`}>
              <DatePicker
                showYearDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={50}
                selected={item.end ? new Date(item.end) : null}
                onChange={(date: any) => {
                  setDisplayButton(true)
                  setExperienceDetails([...experienceDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        end: date,
                      }
                    } else return object
                  }))
                  !date ? setServicePeriodsEndDateError(true) : setServicePeriodsEndDateError(false)
                }}
                dateFormat="MMMM d, yyyy"
                minDate={item.start ? new Date(item.start) : null}
                readOnly={!editMode || !item.start}
                placeholderText="Enter service periods end"
              />
            </div>
            <p className="error-message">This is required field</p>
          </div>
        </div>
      </div>
    )
  }

export default memo(ExperienceDetailsTabItem)