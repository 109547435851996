import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { memo } from "react";

const SubscriptionCard = ({
  setTabDisplay,
  tabDisplay,
  cardData,
  period,
  setSubscriptionData,
  subscriptionData,
  currentSubscription,
  cancelSubscriptionData,
  setCancelSubscriptionData,
  packageData
}) => {
  const { checkUserPermission } = useHasPermission()
  const onChangeSubscriptionData = () => {
    let data: any = {}
    if (period === 'monthly') {
      data.priceId = cardData.prices.month.id;
      data.planId = cardData.planId;
      data.duration = 'm';
      data.packageId = packageData.packageId;
    } else {
      data.priceId = cardData.prices.year?.id;
      data.planId = cardData.planId;
      data.duration = 'y';
      data.packageId = packageData.packageId;
    }
    // console.log({data})
    setSubscriptionData({ ...subscriptionData, popupVisible: true, ...data })
  }

  const currentPlanDisplay = () => {
    // console.log({ currentSubscription })
    if (tabDisplay === 'current') {
      return true
    }
    if (cardData && cardData.hasOwnProperty('isCurrentSubCard') && cardData.isCurrentSubCard) {
      if (period === 'monthly' && currentSubscription && currentSubscription.subId !== 'noSubscription') {
        // console.log({ id: cardData.id, subProdId: currentSubscription.productId })
        return cardData.prices.hasOwnProperty('month') && cardData.prices.month.id === currentSubscription.priceId
      }
      if (period === 'yearly' && currentSubscription && currentSubscription.subId !== 'noSubscription') {
        return cardData.prices.hasOwnProperty('year') && cardData.prices.year.id === currentSubscription.priceId
      }
    }
    return false;
  }

  const cancelSubscription = async () => {
    setCancelSubscriptionData({ ...cancelSubscriptionData, popupVisible: true, subId: currentSubscription.subId })
  }

  const convertCentToDollar = (value) => {
    if (value === Math.floor(value)) {
      return value;
    } else {
      return value.toFixed(2);
    }
  }
  // console.log({cardData})
  return (
    <AuthGuard module='subscription' permission='view'>
      <div className="subscription-card">
        <div className="subscription-card__inner">
          <p className="subscription-card__title">
            {cardData.planName}
          </p>
          {/* {console.log('cardData. ', cardData)}
            {console.log('cardData.prices. ', cardData.prices)} */}
          {
            period === 'monthly' && (
              <>
                <p className="subscription-card__price">
                  ${convertCentToDollar(cardData.prices.month.unit_amount)}<span>/month</span>
                </p>
                <span className="subscription-card__period">Per month</span>
              </>
            )
          }
          {
            period === 'yearly' && (
              <><p className="subscription-card__price">
                ${convertCentToDollar((cardData.prices.year.unit_amount))}<span>/year</span>
              </p>
                <span className="subscription-card__period">Yearly</span>
                <span className="subscription-card__period">${convertCentToDollar((cardData.prices.year.unit_amount / 12))} Per month</span>
              </>
            )
          }

          {
            currentPlanDisplay() ?
              <div className="subscription-card__choose-plan button--empty current-plan">
                Current plan
              </div>
              :
              checkUserPermission(['subscription', 'payment-integration'], ['add'], 'AND') ?
                <div
                  className="subscription-card__choose-plan button--empty button--empty-disable"
                  onClick={() => onChangeSubscriptionData()}
                >
                  {currentSubscription && currentSubscription.subId !== 'noSubscription' ? "Change Plan" : "Get Started"}
                </div>
                : <></>
          }
          <AuthGuard module='subscription' permission={['edit', 'add']}>

            {
              tabDisplay === 'current' && currentSubscription.subId !== 'noSubscription' && checkUserPermission('subscription', 'delete') && (
                <p className="subscription-card__cancel" onClick={cancelSubscription}>Cancel Subscription</p>
              )
            }
          </AuthGuard>
          <ul className="subscription-card__list">
            {
              cardData && cardData.features && cardData.features.map((value, index) => {
                return (
                  <li className="subscription-card__list__item" key={index}>
                    <div className="subscription-card__list__item--icon"></div>
                    <p className="subscription-card__list__item--value">
                      {value}
                    </p>
                  </li>
                )
              })
            }
          </ul>
          {
            tabDisplay == 'current' && (
              <p className="subscription-card__another" onClick={() => setTabDisplay('packages')}>
                See another plans
              </p>
            )
          }
          {
            tabDisplay == 'packages' && (
              <a className="subscription-card__another" href="https://urecruits.com/pricing" target="_blank">
                See more about plan
              </a>
            )
          }
        </div>
      </div>
    </AuthGuard>
  )
}

export default memo(SubscriptionCard)