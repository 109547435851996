import { memo, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CompanyAddressItem from "./CompanyAddressItem";
import PhoneInput from "react-phone-number-input";
import { validatePhoneNumber } from "../../../hook/validatePhoneNumber";
import { selectDisableStyle } from "../../../styles/selectDisableStyle";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { getEnv, GlobalConstants } from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { selectSearchFuncIndustry } from "../../../hook/selectSearchFunc";
import AsyncSelect from "react-select/async";
import Gallery from "./Gallery";
import RichTextEditor from "../../RichTextEditor";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const editIc = require("../../../image/icon/edit_ic.svg");
const plusIc = require("../../../image/icon/plus_ic.svg");
const userAvatar = require("../../../image/icon/avatar.svg");


const CompanyDetail = ({ data, countryList, setRefetch, setDisplayUploadPopup, setEditStatusForPopup, setDisplayGalleryPopup }) => {
	const { API_RECRUITMENT } = getEnv();
	const {checkUserPermission} = useHasPermission()

	const [success, setSuccess] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [displayButton, setDisplayButton] = useState(false);
	const [validateStatus, setValidateStatus] = useState(false);
	const { phoneValidationLength } = GlobalConstants();
	const addedMoreEducationButtonRef = useRef(null);
	const submitButtonRef = useRef(null);

	//form state
	const [avatar, setAvatar] = useState(userAvatar);
	const [companyName, setCompanyName] = useState("");
	const [dataDetails, setDataDetails] = useState([{
		id: uuidv4(),
		street: "",
		location: "",
		country: { value: "", label: "" },
		email: "",
		phone: "",
	}]);
	const [companyType, setCompanyType] = useState("");
	const [companyIndustry, setCompanyIndustry] = useState(null);
	const [contactPersonFirstName, setContactPersonFirstName] = useState("");
	const [contactPersonMiddleName, setContactPersonMiddleName] = useState("");
	const [contactPersonLastName, setContactPersonLastName] = useState("");
	const [contactPersonEmail, setContactPersonEmail] = useState("");
	const [contactPersonPhone, setContactPersonPhone] = useState(null);
	const [aboutTheCompany, setAboutTheCompany] = useState("");
	const [companySpecialists, setCompanySpecialists] = useState(null);
	//TODO: add website to API request
	const [website, setWebsite] = useState("");

	//error state
	const [companyNameError, setCompanyNameError] = useState(false);
	const [companyTypeError, setCompanyTypeError] = useState(false);
	const [companyIndustryError, setCompanyIndustryError] = useState(false);
	const [contactPersonPhoneError, setContactPersonPhoneError] = useState(false);
	const [aboutCompanyValidate, setAboutCompanyValidate] = useState(false);

	const setStates = () => {
		setCompanyIndustry(data.industry);
		setCompanyType(data.company_type);
		setContactPersonFirstName(data.firstname);
		setContactPersonMiddleName(data.middlename);
		setContactPersonLastName(data.lastname);
		setContactPersonEmail(data.email);
		setContactPersonPhone(data.phone);
		setWebsite(data.website);
		setCompanySpecialists(data.companySpecialists);
		setAboutTheCompany(data.about);
		setCompanyName(data.name);
		setDataDetails(data.addresses || dataDetails);
		setAvatar(data.avatar ? data.avatar : userAvatar);
	};

	useEffect(() => {
		if (data) {
			setStates();
		}
	}, [data]);


	const onFormSubmit = async (e) => {
		e.preventDefault();
		!companyName || companyName.length === 0 && (setCompanyNameError(true));
		!companyType && setCompanyTypeError(true);
		!companyIndustry && setCompanyIndustryError(true);
		setAboutCompanyValidate(true);
		try{
		setValidateStatus(false);
		if (validateStatus && companyName && companyType && companyIndustry) {
			const tmpdata = {
				id: data.id,
				name: companyName,
				phone: contactPersonPhone,
				company_type: companyType,
				firstname: contactPersonFirstName,
				middlename: contactPersonMiddleName,
				companySpecialists: companySpecialists,
				website: website,
				lastname: contactPersonLastName,
				about: aboutTheCompany,
				industryId: companyIndustry.id,
				addresses: dataDetails,
			};

			await patchData(`${API_RECRUITMENT}/api/company`, tmpdata);
			setRefetch(new Date());
			setSuccess(true);
		}
		
		setTimeout(() => {
			setDisplayButton(false)
			setEditMode(false)
			setSuccess(false);
		}, 2000);
	}catch(error){
		console.log({error})
	}
	};

	const onFormChange = () => {
		setDisplayButton(true);
	};

	const onClearHandler = (e) => {
		e.preventDefault();
		setEditMode(false);
		setDisplayButton(false);
		setStates();
	};

	const onAddAddressesHandler = async () => {
		if (editMode) {
			const emptyObj = { id: uuidv4(), street: "", location: "", country: { value: "", label: "" }, email: "", phone: "" };

			setValidateStatus(false);

			if (validateStatus) {
				setDataDetails([...dataDetails, emptyObj]);
			}
		}
	};

	useEffect(() => {
		if (aboutTheCompany?.length > 0) {
			setDisplayButton(true);
		}
	}, [aboutTheCompany]);

	return (
		<AuthGuard module='company-profile' permission={['view','edit']}>
		<div className="profile__right__inner">
			<div className="profile__head">
				<div className="profile__head__inner">
					<p className="profile__head__title">
						Company Details {editMode && (<span> - Editing Mode</span>)}
					</p>
					{
						!editMode && (
							<AuthGuard module='company-profile' permission='edit'>
								<p className="profile__head__edit" onClick={() => setEditMode(true)}>
									<span>Edit</span>
									<img src={editIc} alt="edit icon" />
								</p>
							</AuthGuard>
						)
					}
				</div>
			</div>
			<form
				className={`profile__form ${editMode ? "" : "readonly"}`}
				onSubmit={(e) => onFormSubmit(e)}
				onChange={onFormChange}
			>
				{
					dataDetails?.map((item, index) => {
						return (
							<CompanyAddressItem
								dataDetails={dataDetails}
								companyName={companyName}
								contactPersonEmail={contactPersonEmail}
								contactPersonPhone={contactPersonPhone}
								companyType={companyType}
								contactPersonFirstName={contactPersonFirstName}
								contactPersonMiddleName={contactPersonMiddleName}
								contactPersonLastName={contactPersonLastName}
								aboutTheCompany={aboutTheCompany}
								companyIndustry={companyIndustry}
								currentItem={item}
								setDataDetails={setDataDetails}
								editMode={editMode}
								key={index}
								displayButton={displayButton}
								index={index}
								countryList={countryList}
								validateStatus={validateStatus}
								setValidateStatus={setValidateStatus}
								submitButtonRef={submitButtonRef}
								addedMoreEducationButtonRef={addedMoreEducationButtonRef}
								setRefetch={setRefetch}
								companyNameError={companyNameError}
								setCompanyName={setCompanyName}
								setCompanyNameError={setCompanyNameError}
								setEditStatusForPopup={setEditStatusForPopup}
								setDisplayUploadPopup={setDisplayUploadPopup}
								avatar={avatar}
								setDisplayButton={setDisplayButton}
							/>
						);
					})
				}
				{
					editMode &&
					<div className="profile__form__group">
						<div className={`profile__form__more ${!editMode ? "readonly" : ""}`}
							id="educationsMoreButton"
							ref={addedMoreEducationButtonRef}
							onClick={() => onAddAddressesHandler()}
						>
							<img src={plusIc} alt="plust icon"
								className={editMode ? "profile__form__more--icon" : "profile__form__more--icon profile__form__more--icon-invisible"} />
							<p className="profile__form__more--text">Add one more address</p>
						</div>
					</div>
				}
				<div className="profile__form__group">
					<div className={`profile__form__item ${companyTypeError ? "error" : ""}`}>
						<label className="profile__form__label">Company Type{editMode && <span> *</span>}</label>
						<div className="profile__form__types">
							<div className={`profile__form__radio ${!editMode ? "default" : ""}`}>
								<input
									type="radio"
									id="corporateType"
									name="CorporateType"
									value="Corporate"
									checked={companyType === "Corporate"}
									disabled={!editMode}
									onChange={(e) => {
										setCompanyType(e.target.value);
										e.target.value.length <= 0 ? setCompanyTypeError(true) : setCompanyTypeError(false);
									}}
								/>
								<label htmlFor="corporateType">Corporate</label>
							</div>
							<div className={`profile__form__radio ${!editMode ? "default" : ""}`}>
								<input
									type="radio"
									id="consultancyType"
									name="ConsultancyType"
									value="Consultancy"
									checked={companyType === "Consultancy"}
									disabled={!editMode}
									onChange={(e) => {
										setCompanyType(e.target.value);
										e.target.value.length <= 0 ? setCompanyTypeError(true) : setCompanyTypeError(false);
									}}
								/>
								<label htmlFor="consultancyType">Consultancy</label>
							</div>
							<div className={`profile__form__radio ${!editMode ? "default" : ""}`}>
								<input
									type="radio"
									id="OtherType"
									name="OtherType"
									value="Other"
									checked={companyType === "Other"}
									disabled={!editMode}
									onChange={(e) => {
										setCompanyType(e.target.value);
										e.target.value.length <= 0 ? setCompanyTypeError(true) : setCompanyTypeError(false);
									}}
								/>
								<label htmlFor="OtherType">Other</label>
							</div>
						</div>
						<p className="error-message">This is required field</p>
					</div>
					<div className={`profile__form__item ${companyIndustryError ? "error" : ""}`}>
						<label className="profile__form__label">Company Industry{editMode && <span> *</span>}</label>
						<AsyncSelect
							cacheOptions
							closeMenuOnSelect
							loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncIndustry(inputValue) : selectSearchFuncIndustry("")
							}
							defaultOptions
							isDisabled={!editMode}
							value={companyIndustry}
							onChange={(option: any) => {
								setDisplayButton(true);
								setCompanyIndustry(option);
								option.length <= 0 ? setCompanyIndustryError(true) : setCompanyIndustryError(false);
							}}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							placeholder={`${editMode ? "Select Industry(ies)" : "Not indicated"}`}
							id="industrySelect"
							instanceId="industrySelect"
						/>
						<p className="error-message">This is required field</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item short`}>
						<label className="profile__form__label">Contact Person First Name</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter first name" : "Not indicated"}`}
							readOnly={!editMode}
							value={contactPersonFirstName ? contactPersonFirstName : ""}
							onChange={(e) => {
								setContactPersonFirstName(e.target.value);
							}}
						/>
					</div>
					<div className={`profile__form__item short`}>
						<label className="profile__form__label">Contact Person Middle Name</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter middle name" : "Not indicated"}`}
							readOnly={!editMode}
							value={contactPersonMiddleName ? contactPersonMiddleName : ""}
							onChange={(e) => {
								setContactPersonMiddleName(e.target.value);
							}}
						/>
					</div>
					<div className={`profile__form__item short`}>
						<label className="profile__form__label">Contact Person Last Name</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter last name" : "Not indicated"}`}
							readOnly={!editMode}
							value={contactPersonLastName ? contactPersonLastName : ""}
							onChange={(e) => {
								setContactPersonLastName(e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item`}>
						<label className="profile__form__label">Contact Person Email</label>
						<input
							type="email"
							className="profile__form__input readonly"
							placeholder={`${editMode ? "" : "Not indicated"}`}
							readOnly
							value={contactPersonEmail}
						/>
					</div>
					<div className={`profile__form__item ${contactPersonPhoneError ? "error" : ""}`}>
						<label className="profile__form__label">Contact Person Phone</label>
						<PhoneInput
							international
							defaultCountry="US"
							value={contactPersonPhone}
							disabled={!editMode}
							className={!editMode ? "default" : ""}
							placeholder={`${editMode ? "" : "Not indicated"}`}
							onChange={(value: any) => {
								if (value) {
									setContactPersonPhone(value);
									!validatePhoneNumber(value) || value.length > phoneValidationLength ? setContactPersonPhoneError(true) : setContactPersonPhoneError(false);
								} else {
									setContactPersonPhoneError(true);
								}
							}}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${contactPersonPhoneError ? "error" : ""}`}>
						<label className="profile__form__label">Company Specialists</label>
						<input
							type="number"
							className="profile__form__input number"
							placeholder={`${editMode ? "Enter company specialists" : "Not indicated"}`}
							value={companySpecialists}
							disabled={!editMode}
							onChange={(e) => {
								setCompanySpecialists(e.target.value);
							}}
						/>
					</div>
					<div className="profile__form__item">
						{/*//TODO: need to add validate for link*/}
						<label className="profile__form__label">Website</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter company website" : "Not indicated"}`}
							value={website}
							onChange={(e) => {
								setWebsite(e.target.value);
							}}
							disabled={!editMode}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item  long `}>
						<label className="profile__form__label">About the Company</label>
						<RichTextEditor
							value={aboutTheCompany}
							setValue={setAboutTheCompany}
							placeholder={`${editMode ? "Write about your company" : "Not indicated"}`}
							validateFlag={true}
							editMode={editMode}
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item  long`}>
						<Gallery
							galleryID="my-test-gallery"
							editMode={editMode}
							data={data}
							setDisplayGalleryPopup={setDisplayGalleryPopup}
							setRefetch={setRefetch}
						/>
					</div>
				</div>
				{
					editMode?
						<div className="profile__form__group">
							<div className="profile__form__item buttons">
								<button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
								</button>
								<button className="profile__form__submit button--filled" ref={submitButtonRef} type="submit">Save
								</button>
							</div>
							{success ? <p className="success-message">All changes made</p> : null}
						</div>
						: null
				}
			</form>
		</div>
		</AuthGuard>
	);
};

export default memo(CompanyDetail);