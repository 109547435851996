import { memo, useEffect, useState } from 'react';
import { default as ReactSelect } from 'react-select';
import { selectCustomStyle } from '../../../styles/selectCustomStyle';
import { selectDisableStyle } from '../../../styles/selectDisableStyle';
import deleteData from "../../../hook/deleteData";
import { getEnv } from "@urecruits/api";
import { Box, Chip } from '@mui/material';

const deleteIc = require('../../../image/icon/delete_ic.svg')

const SkillsDetailsTabItem =
  ({
     dataDetails,
     currentItem,
     setDataDetails,
     editMode,
     validateStatus,
     setValidateStatus,
     displayButton,
     addedMoreButtonRef,
     submitButtonRef,
     earsOfExperienceOption,
     index,
     setRefetch,
     setDisplayButton,
     setData
  }) => {
    const [skillNameError, setSkillNameError] = useState(editMode ? !currentItem?.name : false)
    const [earsOfExperienceError, setEarsOfExperienceError] = useState(editMode ? !currentItem?.years : false)

    const fieldsValidate = () => {
      let error = 0
      dataDetails.map((object) => {
        !object.name || object.name.length <= 0 ? setSkillNameError(true) : setSkillNameError(false)
        !object.years || object.years.length <= 0 ? setEarsOfExperienceError(true) : setEarsOfExperienceError(false)

        if (!object.years || !object.name || object.name.length <= 0 || object.years.length <= 0) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }

    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, dataDetails, currentItem, editMode])
    const {API_RECRUITMENT}=getEnv()

    const onRemoveItemHandler = async (e) => {
      e.preventDefault
      if (editMode) {
        if (currentItem?.isResumeParsed||typeof currentItem?.id !== 'number') {
          setData(oldData => {
            let skills = [];
            skills = dataDetails?.filter(i => i.id !== currentItem.id)
            oldData.skills = skills;
            return { ...oldData }
          });
        } else {
          const res = await deleteData(`${API_RECRUITMENT}/api/candidate/skill`, currentItem)
          if (res.status == 200) {
            setData(oldData => {
              const _oldData = JSON.parse(JSON.stringify(oldData));
              let skills = dataDetails;
              skills = skills.filter(item => item.id !== currentItem.id);
              return { ..._oldData, skills }
            })
          }
        }
      }
    }

    return (
      <div className="profile__form__part">
        <div className={`flex`}>
          <Box>
            {currentItem?.isResumeParsed && <Chip variant='outlined' color='error' label="Unsaved" />}
          </Box>
          {editMode&&<div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
            <div className="profile__form__delete--inner" onClick={(e) => onRemoveItemHandler(e)}>
              <span className="profile__form__delete--text">Delete</span>
              <img src={deleteIc} alt="delete icon" className="profile__form__delete--icon" />
            </div>
          </div>}
        </div>
        <div className="profile__form__group">
          <div className={`profile__form__item ${skillNameError ? 'error' : ''}`}>
            <label className="profile__form__label">Skill Name{editMode&&<span> *</span>}</label>
            <input
              type="text"
              className="profile__form__input"
              placeholder={`${editMode ? 'Enter skill name' : 'Not indicated'}`}
              readOnly={!editMode}
              value={currentItem.name?currentItem.name:''}
              onChange={(e) => {
                setDataDetails([...dataDetails].map(object => {
                  if (object.id === currentItem.id) {
                    return {
                      ...object,
                      name: e.target.value,
                    }
                  } else return object
                }))
                e.target.value.length <= 0 ? setSkillNameError(true) : setSkillNameError(false)
              }}
            />
            <p className="error-message">This is required field</p>
          </div>
          <div className={`profile__form__item ${earsOfExperienceError ? 'error' : ''}`}>
            <label className="profile__form__label">Years of Experience in the skills{editMode&&<span> *</span>}</label>
            <ReactSelect
              options={earsOfExperienceOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setDisplayButton(true)
                setDataDetails([...dataDetails].map(object => {
                  if (object.id === currentItem.id) {
                    return {
                      ...object,
                      years: item.label,
                    }
                  } else return object
                }))
                setEarsOfExperienceError(false)
              }}
              value={currentItem.years?earsOfExperienceOption.find(x => x.label === currentItem.years):''}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select years of experience' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="earsOfExperienceSelect"
              instanceId="earsOfExperienceSelect"
            />
            <p className="error-message">This is required field</p>
          </div>
        </div>
      </div>
    )
  }

export default memo(SkillsDetailsTabItem)