import { useEffect, useState } from 'react';
import { getEnv } from '@urecruits/api';
import { Skeleton, Stack } from '@mui/material';
import ViewUsageRecord from './UsagePopup';



const { API_RECRUITMENT } = getEnv()
const UpcomingInvoiceComponent = ({ data, isAddon = false }: { data: any, isAddon?: boolean }) => {
  const [upcomingInvoice, setUpcomingInvoice] = useState([])
  const [amounts, setAmounts] = useState({ payableAmount: 0, total: 0, tax: 0, willInvoiceOn: '' })
  const [loading, setLoading] = useState(false)
  const [empty, setEmpty] = useState(true)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const [showUsage, setShowUsage] = useState(false);
  const [usageData, setUsageData] = useState(null);

  const showViewUsage = (addOn) => {
    setUsageData(addOn);
  };

  useEffect(() => {
    if (usageData) {
      setShowUsage(true);
    }
  }, [usageData])

  useEffect(() => {
    if (!data) {
      setEmpty(true)
    } else if (data) {

      const { count, rows, payableAmount, tax, total, willInvoiceOn } = data
      if (count) {
        setUpcomingInvoice(rows)
        const date = new Date(willInvoiceOn * 1000)
        setAmounts(prev => ({ ...prev, payableAmount, tax, total, willInvoiceOn: `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })} ${date.toLocaleString('en-US', { year: '2-digit' })}` }))
      }
    }
    setLoading(false)
  }, [])

  const tableWaves: any = []
  for (let i = 0; i < 7; i++) {
    tableWaves.push(<Skeleton animation="wave" key={i} />)
  }

  return (
    <div>
      <div className="invoices__head">
        <p className="invoices__head__title">Upcoming Invoice {isAddon && "for Add on's"}</p>
        {!loading && <>
          {(amounts.willInvoiceOn)
            ? <p className='invoices__head__description'>This is a preview of the invoice that will be billed on {amounts.willInvoiceOn}. It may change if the subscription is updated.</p>
            : <p className='invoices__head__description'>There is no upcoming invoices for the current subscription.</p>
          }
        </>}
      </div>
      {screenWidth > 768 ? <UpcomingInvoicesTable
        amounts={amounts}
        loading={loading}
        onUsageClick={value => showViewUsage(value)}
        tableWaves={tableWaves}
        upcomingInvoice={upcomingInvoice}
      />
        :
        <UpcomingInvoicesMobileTable
          amounts={amounts}
          loading={loading}
          onUsageClick={value => showViewUsage(value)}
          tableWaves={tableWaves}
          upcomingInvoice={upcomingInvoice}
        />}
      {
        showUsage && <ViewUsageRecord addOnUsage={usageData} onClose={() => { setShowUsage(false); setUsageData(null) }} />
      }
    </div>
  )
}

export default UpcomingInvoiceComponent

const UpcomingInvoicesTable = ({ upcomingInvoice, loading, tableWaves, amounts, onUsageClick }) => {
  return <table>
    {loading && <Stack height={"100%"}>
      {
        tableWaves
      }
    </Stack>}
    {!!upcomingInvoice.length && !loading && <>
      {<>
        <thead>
          <th>Name</th>
          <th>Price/unit</th>
          <th>Used Quantity</th>
          <th>Amount</th>

        </thead>
        <tbody>
          {
            upcomingInvoice.map((invoice, index) => {
              return <tr key={index}>
                <td>{invoice.name} </td>
                <td>${invoice.perUnitPrice}</td>
                <td>{invoice.quantity} {invoice.hasOwnProperty('add_on') ? <sub onClick={() => onUsageClick(invoice.add_on)}> usage</sub> : ''}</td>
                <td>${(invoice.amount / 100).toFixed(2)}</td>
              </tr>
            })
          }
          <tr></tr>
          <tr>
            <td></td>
            <td></td>
            <td>Payable amount:</td>
            <td>${(amounts.payableAmount / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Tax (in $):</td>
            <td>${(amounts.tax / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Total Payable amount:</td>
            <td>${(amounts.total / 100).toFixed(2)}</td>
          </tr>
        </tbody>
      </>

      }
    </>}
  </table>
}

const UpcomingInvoicesMobileTable = ({ upcomingInvoice, loading, tableWaves, amounts, onUsageClick }) => {

  return <>
    {loading && <Stack height={"100%"}>
      {
        tableWaves
      }
    </Stack>}
    {!!upcomingInvoice.length && !loading && <>
      <ul className='invoices__list'>
        {
          upcomingInvoice.map((invoice, index) => {
            return <li key={index} className='invoices__list__item'>
              <p className='invoices__list__item__title'>{invoice.name} </p>
              <div className='invoices__list__item__content'>
                <p className='invoices__list__item__content__text'>Unit Price: ${invoice.perUnitPrice}</p>
                <p className='invoices__list__item__content__text'>Qty: {invoice.quantity} {invoice.hasOwnProperty('add_on') ? <sub onClick={() => onUsageClick(invoice.add_on)}> usage</sub> : ''}</p>
                <p className='invoices__list__item__content__text'>Amount: ${(invoice.amount / 100).toFixed(2)}</p>
              </div>
            </li>
          })
        }
      </ul>
      <table className='invoices__list__total'>
        <tbody>
          <tr>
            <td>Payable amount:</td>
            <td>${(amounts.payableAmount / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tax:</td>
            <td>${(amounts.tax / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Total Payable amount:</td>
            <td>${(amounts.total / 100).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </>
    }
  </>
}