
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { memo } from "react";
import HellosignCard from "./HellosignCard";
import UniversalCard from "./UniversalCard";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();


const IntegrationCard = ({ cardItem, index }) => {
  
  const isHellosign = cardItem.id === "hellosign26042024";
  const isUniversal = cardItem.id === "universal31052024";
  const customIntegration = isHellosign || isUniversal;
  
  const handleDisconnect = (provider) => {
    axios
      .get(
        `${API_RECRUITMENT}/api/integrations/auth-revoke?provider=${provider}`,
        getConfig()
      )
      .then((res) => {
        if (res.status == 200) {
          const disconnectUrl = `/profile-setting?tab=integrations&action=disconnect&provider=${provider}&success=true`;
          window.history.pushState(null, null, disconnectUrl);
          window.location.reload();
        }
      })
     ;
  };

  return (
    <div className={`integrations__card ${customIntegration ? "long" : ""}`}>
      <div className="integrations__card__head">
        <div className="integrations__card__img">
          <img src={cardItem.image} alt={cardItem.name} />
        </div>
        {cardItem?.status === "connected" && (
          <div className="integrations__card__connected">Connected</div>
        )}
      </div>
      <div className="integrations__card__body">
        <p className="integrations__card__name">{cardItem.name}</p>
        <p
          className={`integrations__card__description ${
            customIntegration ? "auto-height" : ""
          }`}
        >
          {cardItem.description}
        </p>
        {isHellosign && (
         <HellosignCard/>
        )}

        {isUniversal && <UniversalCard/>}

        {cardItem.connectUrl&&<div className="integrations__card__integration-button">
          {!customIntegration && (
            <a
              href={cardItem?.status !== "connected" && cardItem.connectUrl}
              target="_blank"
              style={{
                background: cardItem?.status === "connected" && "#a8a8a7",
              }}
              className="integrations__card__integration-button__button button--filled"
            >
              Connect
            </a>
          )}
          {cardItem?.status === "connected" && (
            <button
              className="integrations__card__integration-button__button button--empty"
              onClick={() => {
                handleDisconnect(cardItem.key);
              }}
            >
              Disconnect
            </button>
          )}
        </div>}
      </div>
    </div>
  );
};

export default memo(IntegrationCard);
